import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import {Space} from 'antd';
function OldCards(props) {

  return (
    <>
      <Space direction='vertical' style={{ marginBottom: "45px", marginLeft: "20px"}}>
        <div style={{ marginLeft: "20px", marginTop: "50px",  marginBottom: "20px", fontSize: "25px" }}><strong>Past Courses</strong></div>
        {/* <Space style={{ margin: "0 auto",  justifyContent: "space-evenly" }} align='center' size="large" wrap> */}
        <Space style={{ marginLeft: "20px", marginRight: "20px", justifyContent: "start" }} align='start' size="large" wrap>

            <CardItem
              className="class"
              src='images/cse121.jpg'
              text='CSE121'
              label='300 USD'
              courseName="CSE121"
              path='/payment'
              isLoggedIn = {props.isLoggedIn}
            />
            <CardItem
              className="class"
              src='images/amath301.png'
              text='AMATH301'
              label='300 USD'
              courseName="AMATH301"
              path='/payment'
              isLoggedIn = {props.isLoggedIn}
            />
            <CardItem
              className="class"
              src='images/math124.jpg'
              text='MATH124'
              label='300 USD'
              path='/payment'
              courseName="MATH124"
              isLoggedIn = {props.isLoggedIn}
            />
            <CardItem
              className="class"
              src='images/chem142.jpeg'
              text='CHEM142'
              label='300 USD'
              path='/payment'
              courseName="CHEM142"
              isLoggedIn = {props.isLoggedIn}
            />
            <CardItem
              className="class"
              src='images/cse122.jpg'
              text='CSE122'
              label='300 USD'
              path='/payment'
              courseName="CSE122"
              isLoggedIn = {props.isLoggedIn}
            />
        </Space>
      </Space>
    </>
  );
}

export default OldCards;
